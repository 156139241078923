import styled from 'styled-components';
import RSelect from 'react-select';

export const StyledSelect = styled(RSelect)`
  border-right: 1px solid #e3e3e8;
  border-left: 1px solid #e3e3e8;
  margin: 0 20px;
  @media screen and (max-width: 640px) {
    margin: 0 5px;
  }
  .styled-select__control {
    width: 90px;
    height: 100%;
    margin: 0 12px;
    cursor: pointer;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    background-color: ${props => props.theme.colors.mainBackground};
    transition: none;
    @media screen and (max-width: 640px) {
      width: 70px;
      margin: 0 2px;
    }
    .styled-select__value-container {
      text-align: center;
      padding: 2px;
      display: inline-flex;
      align-items: center;
      .styled-select__single-value {
        font-family: ${props => props.theme.fonts.Montserrat};
        font-weight: ${props => props.theme.fontWeights.Montserrat.semiBold};
        font-size: 22px;
        color: ${props => props.theme.colors.secondary};

        @media screen and (max-width: 640px) {
          font-size: 14px;
        }
      }

      .css-mohuvp-dummyInput-DummyInput {
        ${({ theme }) => theme.rtl && 'left : 0'};
      }
      .styled-select__input-container {
        color: ${props => props.theme.colors.secondary};

        .styled-select__input {
          /* font-family: ${props => props.theme.fonts.SourceSansPro};
          font-size: 14px;*/
        }
      }
    }
    .styled-select__indicators {
      .styled-select__indicator-separator {
        display: none;
      }
      .styled-select__indicator {
        color: ${props => props.theme.colors.secondary};
        padding: 2px;
      }
    }
  }

  .styled-select__menu {
    background-color: ${props => props.theme.colors.mainBackground};

    .styled-select__menu-list
      .styled-select__option--is-focused
      :not(.styled-select__option--is-selected) {
      background-color: ${props => props.theme.colors.secondaryBackground};
    }
  }
`;

export const StyledSelectForPaymentProcess = styled.div`
  .react-select-container {
    height: 35px;
    border: none !important;
    .react-select__control {
      height: 100%;
      border: none;
      border-bottom: 1px solid ${props => props.theme.colors.disabledGray};
      border-radius: 0;
      outline: none !important;
      background-color: ${props => props.theme.colors.mainBackground};
      min-height: 35px;
      .react-select__value-container {
        height: 100%;
        padding: 0;
        display: flex;
        align-items: center;
        .react-select__single-value {
          font-family: ${props => props.theme.fonts.Montserrat};
          font-weight: ${props => props.theme.fontWeights.Montserrat.bold};
          font-size: 20px;
          color: ${props => props.theme.colors.secondary};
          text-transform: uppercase;
          margin: 0;
          padding-left: 20px;
          max-width: none;
          &:before {
            width: 30px;
            height: 20px;
            border-radius: 0;
            margin-right: 5px;
          }
          @media screen and (max-width: 1024px) {
            padding-left: 10px;
            font-size: 14px;
          }
        }
      }
    }

    .react-select__menu {
      background-color: ${props => props.theme.colors.mainBackground};

      .react-select__menu-list
        .react-select__option--is-focused
        :not(.react-select__option--is-selected) {
        background-color: ${props => props.theme.colors.secondaryBackground};
      }

      .react-select__option {
        text-transform: uppercase;
      }

      .react-select__menu-notice--no-options {
        font-family: ${props => props.theme.fonts.Montserrat};
        font-weight: ${props => props.theme.fontWeights.regular};
        font-size: 14px;
      }
    }

    .react-select__indicators {
      width: 42px;
      .react-select__indicator-separator {
        display: none;
      }
      .react-select__indicator {
        padding: 0;
        transform: translateY(4px);
        svg {
          width: 24px;
          height: 16px;
          path {
            fill: ${props => props.theme.colors.secondary};
            d: path(
              'M23.557,1.426 L12.970,10.277 C12.778,10.437 12.518,10.491 12.268,10.462 C12.019,10.491 11.759,10.437 11.567,10.277 L0.980,1.426 C0.655,1.155 0.655,0.714 0.980,0.443 C1.305,0.171 1.831,0.171 2.156,0.443 L12.268,8.896 L22.381,0.443 C22.705,0.171 23.232,0.171 23.557,0.443 C23.882,0.714 23.882,1.155 23.557,1.426 Z'
            );
          }
        }
      }
    }
  }
  @media screen and (max-width: 640px) {
    width: 100%;
    /* margin-bottom: 38px; */
  }
`;
