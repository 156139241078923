import {
  ChangeEvent,
  FocusEvent,
  forwardRef,
  memo,
  ClipboardEvent,
} from 'react';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import { Label } from '../common';

export const InputStyled = styled.input<{
  hasError?: boolean;
  isForPaymentProcess?: boolean;
}>`
  font-family: ${props => props.theme.fonts.SourceSansPro};
  font-size: 14px;
  border: ${props =>
    props.hasError
      ? `2px solid ${props.theme.colors.danger}`
      : '0 solid transparent'};
  border-radius: 30px;
  box-shadow: 0 0 20px 0 ${props => props.theme.colors.shadow};
  height: 60px;
  width: 100%;
  padding: ${props => (props.theme.rtl ? '0 20px 0 0' : '0 0 0 20px')};
  transition: all 0.2s ease;
  background-color: ${props => props.theme.colors.mainBackground};
  color: ${props => props.theme.colors.secondary};
  &::placeholder {
    opacity: 0.8;
    text-transform: uppercase;
    color: ${props => props.theme.colors.secondary};
  }
  ${({ disabled }) => disabled && 'cursor : not-allowed;'}
  @media only screen and (max-width: 1024px) {
    font-size: 14px;
    height: 50px;
    padding-left: 20px;
  }

  ${({ isForPaymentProcess = false, theme }) =>
    isForPaymentProcess &&
    `
    font-family: ${theme.fonts.Montserrat};
     border: none;
     border-bottom: 1px solid  ${theme.colors.disabledGray};
     border-radius : 0;
     box-shadow: none;
     height: 35px;
     font-size: 20px;
     font-weight: ${theme.fontWeights.Montserrat.bold};
     @media only screen and (max-width: 1024px) {
      height: 35px;
      }
      @media only screen and (max-width: 1024px) {
        padding-left: 10px;
        font-size: 14px;
      }
    `}

  &[name='phoneNumber'] {
    ${({ theme }) =>
      theme.rtl &&
      `
       direction: ltr;
    text-align: right;
       `}
  }
`;

const SerialInput = styled(NumberFormat)<{ hasError?: boolean }>`
  font-family: ${props => props.theme.fonts.Montserrat};
  font-weight: ${props => props.theme.fontWeights.Montserrat.semiBold};
  font-size: 22px;
  border: none;
  width: 100%;
  /* background-color: ${props => props.theme.colors.mainBackground}; */
  background-color: transparent;
  color: ${props => props.theme.colors.secondary};
  ${({ disabled }) => disabled && 'cursor : not-allowed;'}
  &::placeholder {
    opacity: 0.5;
    color: ${props => props.theme.colors.secondary};
  }
  @media only screen and (max-width: 1024px) {
    font-size: 20px;
  }
  @media only screen and (max-width: 640px) {
    font-size: 14px;
  }
`;

const TextAreaStyled = styled(InputStyled)`
  padding-top: 20px;
  height: 145px;
  resize: none;
  ${({ disabled }) => disabled && 'cursor : not-allowed;'}
`;

interface InputProps {
  name?: string;
  placeholder?: string;
  type?: 'text' | 'email' | 'number' | 'serial' | 'text-area' | 'phone';
  disabled?: boolean;
  autoFocus?: boolean;
  // rows?: number;
  allowNegative?: boolean;
  thousandSeparator?: string | boolean;
  limit?: number;
  value?: string | number;
  // eslint-disable-next-line no-unused-vars
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  // eslint-disable-next-line no-unused-vars
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  // eslint-disable-next-line no-unused-vars
  onPaste?: (e: ClipboardEvent<HTMLInputElement>) => void;
  hasError?: boolean;
  mask?: string;
  format?: string;
  isForPaymentProcess?: boolean;
}

/* const CustomInput = forwardRef<
  HTMLInputElement | HTMLTextAreaElement,
  InputProps &
ReturnType<UseFormRegister<{ [key: string]: string | number | boolean }>>
> */

const CustomInput = forwardRef(
  (
    {
      name,
      type,
      disabled,
      autoFocus,
      placeholder,
      onChange,
      onBlur,
      // rows = 5,
      allowNegative = false,
      thousandSeparator = ' ',
      limit,
      value,
      onPaste = () => {},
      hasError = false,
      mask = '_',
      format,
      isForPaymentProcess = false,
    }: InputProps,
    ref,
  ) => {
    // console.log({ name, value });

    switch (type) {
      case 'text-area':
        return (
          <>
            <TextAreaStyled
              name={name}
              disabled={disabled}
              autoFocus={autoFocus}
              placeholder={placeholder}
              ref={ref as any}
              onChange={onChange}
              onBlur={onBlur}
              // @ts-ignore
              as="textarea"
              hasError={hasError}
            />
          </>
        );
      case 'serial':
        return (
          <SerialInput
            name={name}
            disabled={disabled}
            autoFocus={autoFocus}
            placeholder={placeholder}
            ref={ref}
            onChange={onChange}
            onBlur={onBlur}
            allowNegative={allowNegative}
            thousandSeparator={thousandSeparator}
            thousandsGroupStyle="thousand"
            value={value}
            {...(limit && {
              isAllowed: ({ value: val }: { value: string }) =>
                val.length <= limit,
            })}
            hasError={hasError}
          />
        );
      case 'number':
        return (
          <>
            {isForPaymentProcess && <Label htmlFor={name}>{placeholder}</Label>}

            <InputStyled
              name={name}
              disabled={disabled}
              autoFocus={autoFocus}
              placeholder={isForPaymentProcess ? '' : placeholder}
              ref={ref}
              onChange={onChange}
              onBlur={onBlur}
              allowNegative={allowNegative}
              thousandSeparator={thousandSeparator}
              thousandsGroupStyle="thousand"
              value={value}
              {...(limit && {
                isAllowed: ({ value: val }: { value: string }) =>
                  val.length <= limit,
              })}
              as={NumberFormat}
              isForPaymentProcess={isForPaymentProcess}
            />
          </>
        );

      case 'phone':
        return (
          <>
            {isForPaymentProcess && <Label htmlFor={name}>{placeholder}</Label>}
            <InputStyled
              name={name}
              disabled={disabled}
              autoFocus={autoFocus}
              placeholder={placeholder}
              ref={ref}
              onChange={onChange}
              onBlur={onBlur}
              allowNegative={allowNegative}
              thousandSeparator={thousandSeparator}
              value={value}
              as={NumberFormat}
              mask={mask}
              format={format}
              allowEmptyFormatting
              isForPaymentProcess={isForPaymentProcess}
            />
          </>
        );

      default:
        return (
          <>
            {isForPaymentProcess && <Label htmlFor={name}>{placeholder}</Label>}
            <InputStyled
              name={name}
              type={type}
              value={value}
              disabled={disabled}
              autoFocus={autoFocus}
              placeholder={isForPaymentProcess ? '' : placeholder}
              ref={ref as any}
              onChange={onChange}
              onBlur={onBlur}
              onPaste={onPaste}
              hasError={hasError}
              isForPaymentProcess={isForPaymentProcess}
            />
          </>
        );
    }
  },
);

export default memo(CustomInput);
