import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Label = styled.label<{ paddingLeft?: string }>`
  font-family: ${props => props.theme.fonts.SourceSansPro};
  font-size: 14px;
  color: ${props => props.theme.colors.disabledGray};
  text-transform: uppercase;
  display: block;
  padding-left: ${({ paddingLeft = '20px' }) => paddingLeft};
  ${({ theme }) =>
    theme.rtl &&
    `
    padding-right: ${({ paddingLeft = '20px' }) => paddingLeft};
    padding-right: 0;
  `}
  @media screen and (max-width: 1024px) {
    font-size: 12px;
    padding-left: 10px;
  }
`;
