import RSelect, { ActionMeta } from 'react-select';
import { forwardRef, memo, useCallback, useMemo, FocusEvent } from 'react';
import { Label } from '../common';
import { StyledSelect, StyledSelectForPaymentProcess } from './styles';

interface Option {
  value: string;
  label: string;
}
// import customStyles from './selectStyles';

interface SelectProps {
  options: Array<Option>;
  defaultValue?: Option;
  value: any;
  name: string;
  // eslint-disable-next-line no-unused-vars
  onChange?: (option: Option | null, actionMeta?: ActionMeta<Option>) => void;
  // eslint-disable-next-line no-unused-vars
  onBlur?: (e: FocusEvent<HTMLElement>) => void;
  // styles?: object;
  isForPaymentProcess?: boolean;
  label?: string;
  isRtl?: boolean;
}

const Select = forwardRef(
  (
    {
      options,
      name,
      onChange = () => {},
      onBlur = () => {},
      value: selectedValue,
      defaultValue,
      isForPaymentProcess = false,
      label,
      isRtl,
    }: SelectProps,
    ref,
  ) => {
    // console.log('rendered Select', name, { selectedValue, defaultValue });

    const selectedOption = useMemo(
      () => options.find(option => option.value === selectedValue),
      [selectedValue, options],
    );

    const onChangeCb = useCallback(
      option => onChange(option?.value),
      [onChange],
    );

    return (
      <>
        {isForPaymentProcess ? (
          <>
            <Label htmlFor={name} paddingLeft="20px">
              {label}
            </Label>
            <StyledSelectForPaymentProcess>
              <RSelect
                options={options}
                name={name}
                onChange={onChangeCb}
                onBlur={onBlur}
                value={selectedOption}
                ref={ref as any}
                defaultValue={defaultValue}
                /*  getOptionLabel={(option: Option) => option.label}
            getOptionValue={(option: Option) => option.value} */
                className="react-select-container"
                classNamePrefix="react-select"
                isSearchable={false}
                isRtl={isRtl}
              />
            </StyledSelectForPaymentProcess>
          </>
        ) : (
          <StyledSelect
            options={options}
            name={name}
            onChange={onChangeCb}
            onBlur={onBlur}
            value={selectedOption}
            ref={ref as any}
            defaultValue={defaultValue}
            /*  getOptionLabel={(option: Option) => option.label}
        getOptionValue={(option: Option) => option.value} */
            className="styled-select-container"
            classNamePrefix="styled-select"
            isSearchable={false}
            isRtl={isRtl}
          />
        )}
      </>
    );
  },
);
export default memo(Select);
